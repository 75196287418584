import orders from './columns/orders';
import jobs from './columns/jobs';
import titles from './columns/titles';
import theme from './theme';
import jobDetails from './jobDetails';
import dataConfig from './dataConfig';
import dashboardConfig from './dashboardConfig';
import titleConfig from './titleConfig';
import orderPlacementConfig from './orderPlacementConfig';
import pendingAssets from './columns/pendingAssets';
import { DeepPartial } from '@/redux/slices/slice_types';
import { ClientConfig } from '@/__configs/config_types';

const config: DeepPartial<ClientConfig> = {
  clientName: 'Crunchyroll',
  theme,
  columns: {
    orders,
    jobs,
    titles,
    pendingAssets
  },
  jobDetails,
  dataConfig,
  dashboardConfig,
  titleConfig,
  orderPlacementConfig,
  versionColors: {
    simulcast: '#9147e9',
    uncut: '#eba228'
  },
  notifications: true,
  enableUserDocumentation: true,
  enableOrderIdFilter: true,
  enableBulkSelect: true
};

export default config;
